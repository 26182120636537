import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { updatePost, deletePost } from "@/app/reducers/PostsReducer";
import { AppDispatch } from "@/app/store";
import { Post } from "@/components/services/types/posts";
import Modal from "@/components/shared/Modal";
import { EuroIcon } from "lucide-react";

interface EditPostModalProps {
  isOpen: boolean;
  onClose: () => void;
  post: Post | null;
}

function EditPostModal({ isOpen, onClose, post }: EditPostModalProps) {
  const dispatch = useDispatch<AppDispatch>();
  const [formData, setFormData] = useState<FormData>(new FormData());
  const [preview, setPreview] = useState<string | null>(null);
  const [status, setStatus] = useState(post?.status || "active");
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (post && isOpen) {
      setIsImageLoading(true);
      setPreview(null);

      const newFormData = new FormData();
      Object.entries(post).forEach(([key, value]) => {
        if (value !== null) {
          newFormData.append(key, value.toString());
        }
      });
      setFormData(newFormData);

      const isVideo = /\.(mp4|mov)(\?|$)/i.test(post.image_url);

      setTimeout(() => {
        setPreview(post.image_url);
        if (isVideo) {
          setIsImageLoading(false);
        }
      }, 100);

      setStatus(post.status);
    }
  }, [post, isOpen]);

  useEffect(() => {
    return () => {
      if (videoUrl) {
        URL.revokeObjectURL(videoUrl);
      }
    };
  }, [videoUrl]);

  const resetModal = () => {
    if (!post) {
      setFormData(new FormData());
      setPreview(null);
      setStatus("active");
    }
    setIsImageLoading(false);
  };

  const handleClose = () => {
    resetModal();
    onClose();
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newFormData = new FormData();
    formData.forEach((value, key) => newFormData.append(key, value));
    newFormData.set(e.target.name, e.target.value);
    setFormData(newFormData);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      // Vérification de la taille du fichier (100MB = 100 * 1024 * 1024 bytes)
      const maxSize = 100 * 1024 * 1024; // 100MB en bytes
      if (file.size > maxSize) {
        alert("Le fichier est trop volumineux. La taille maximale est de 100MB.");
        return;
      }

      setIsImageLoading(true);

      if (videoUrl) {
        URL.revokeObjectURL(videoUrl);
      }

      const newFormData = new FormData();
      formData.forEach((value, key) => newFormData.append(key, value));
      newFormData.set('image', file);
      setFormData(newFormData);

      if (file.type.startsWith('video/')) {
        const newVideoUrl = URL.createObjectURL(file);
        setVideoUrl(newVideoUrl);
        setPreview(newVideoUrl);
        setIsImageLoading(false);
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview(reader.result as string);
          setIsImageLoading(false);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleSelectChange = (value: string) => {
    setStatus(value as "active" | "inactive");
    const newFormData = new FormData();
    formData.forEach((value, key) => newFormData.append(key, value));
    newFormData.set("status", value);
    setFormData(newFormData);
  };

  const handleSubmit = async () => {
    if (post?.id) {
      setIsSubmitting(true);
      try {
        await dispatch(updatePost({ id: post.id, formData }));
        handleClose();
      } catch (error) {
        console.error('Erreur lors de la mise à jour:', error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleDelete = () => {
    if (post?.id) {
      const confirmDelete = window.confirm(
        "Voulez-vous vraiment supprimer cette publication ?"
      );
      if (confirmDelete) {
        dispatch(deletePost(post.id));
        handleClose();
      }
    }
  };

  if (!post) return null;

  const modalContent = (
    <div className="flex gap-10 h-[650px] bg-background">
      {/* Colonne de gauche - Formulaire */}
      <div className="flex-[1.2] overflow-y-auto pr-6">
        <form
          onSubmit={(e) => e.preventDefault()}
          className="space-y-8"
          aria-label="Modifier la publication"
        >
          {/* En-tête du formulaire */}
          <div className="pb-6 border-b border-border">
            <h2 className="text-2xl font-bold text-foreground">
              Détails de la publication
            </h2>
            <p className="text-sm text-muted-foreground mt-1">
              Modifiez les informations de votre publication ci-dessous
            </p>
          </div>

          {/* Titre */}
          <div className="space-y-4">
            <Label
              htmlFor="title"
              className="text-base font-semibold flex items-center gap-1"
            >
              Titre
              <span className="text-destructive text-sm">*</span>
            </Label>
            <div className="relative">
              <Input
                id="title"
                name="title"
                defaultValue={post?.title}
                onChange={handleInputChange}
                required
                className="h-14 text-lg font-medium bg-muted/40 border-2 focus:border-primary"
                placeholder="Ex: Formation complète sur le développement web"
                aria-required="true"
              />
            </div>
          </div>

          {/* Prix et Statut */}
          <div className="grid grid-cols-2 gap-8">
            <div className="space-y-4">
              <Label
                htmlFor="price"
                className="text-base font-semibold flex items-center gap-1"
              >
                Prix
                <span className="text-destructive text-sm">*</span>
              </Label>
              <div className="relative">
                <Input
                  id="price"
                  name="price"
                  type="number"
                  step="0.01"
                  defaultValue={post?.price}
                  onChange={handleInputChange}
                  required
                  className="h-14 text-lg pl-12 font-semibold bg-muted/40 border-2 focus:border-primary"
                  placeholder="0.00"
                  aria-required="true"
                />
                <div className="absolute left-4 top-1/2 -translate-y-1/2 flex items-center gap-2">
                  <EuroIcon
                    className="h-5 w-5 text-muted-foreground"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>

            <div className="space-y-4">
              <Label
                htmlFor="status"
                className="text-base font-semibold flex items-center gap-1"
              >
                Statut
                <span className="text-destructive text-sm">*</span>
              </Label>
              <Select
                value={status}
                onValueChange={handleSelectChange}
                name="status"
                aria-required="true"
              >
                <SelectTrigger className="h-14 bg-muted/40 border-2 focus:border-primary">
                  <SelectValue placeholder="Choisir un statut" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="active">
                    <div className="flex items-center gap-2 py-1">
                      <div className="h-3 w-3 rounded-full bg-emerald-500 animate-pulse" />
                      <span className="font-medium">Actif</span>
                    </div>
                  </SelectItem>
                  <SelectItem value="inactive">
                    <div className="flex items-center gap-2 py-1">
                      <div className="h-3 w-3 rounded-full bg-red-500" />
                      <span className="font-medium">Inactif</span>
                    </div>
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>

          {/* Upload de média */}
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <Label htmlFor="image" className="text-base font-semibold">
                Média
              </Label>
              <span className="text-sm text-muted-foreground">
                JPG, PNG, GIF, MP4, MOV • Max 100MB
              </span>
            </div>
            <div className="relative group">
              <Input
                id="image"
                name="image"
                type="file"
                accept="image/*,video/*"
                onChange={handleFileChange}
                className="h-14 text-base cursor-pointer file:cursor-pointer file:border-0
                         file:bg-primary/10 file:hover:bg-primary/20 file:text-primary
                         file:h-full file:mr-4 file:px-6 file:font-medium transition-all
                         border-2 border-dashed group-hover:border-primary"
              />
            </div>
          </div>
        </form>
      </div>

      {/* Colonne de droite - Prévisualisation */}
      <div className="flex-[2] border-l border-border pl-10">
        <div className="pb-6 mb-6 border-b border-border">
          <h2 className="text-2xl font-bold text-foreground">Aperçu</h2>
          <p className="text-sm text-muted-foreground mt-1">
            Prévisualisez votre contenu avant de le publier
          </p>
        </div>

        <div className="h-[calc(100%-100px)] rounded-xl overflow-hidden bg-muted/30 border-2 border-dashed mb-4">
          {preview ? (
            <div className="relative w-full h-full group flex items-center justify-center">
              {videoUrl || /\.(mp4|mov)(\?|$)/i.test(preview) ? (
                <video
                  src={videoUrl || preview}
                  controls
                  className="max-w-full h-full object-contain rounded-lg p-2 transition-opacity duration-300"
                  onLoadedData={() => setIsImageLoading(false)}
                />
              ) : (
                <img
                  src={preview}
                  alt="Prévisualisation"
                  className="max-w-full h-full object-contain rounded-lg p-2 transition-opacity duration-300 group-hover:scale-[1.02]"
                  onLoad={() => setIsImageLoading(false)}
                  style={{ opacity: isImageLoading ? 0 : 1 }}
                />
              )}
              {isImageLoading && (
                <div className="absolute inset-0 flex items-center justify-center bg-background/50 backdrop-blur-sm">
                  <div className="flex flex-col items-center gap-4">
                    <div className="animate-spin rounded-full h-12 w-12 border-4 border-primary border-t-transparent"></div>
                    <p className="text-sm font-medium text-muted-foreground">
                      Chargement...
                    </p>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="h-full flex flex-col items-center justify-center text-center p-8">
              <div className="w-20 h-20 rounded-full bg-primary/10 flex items-center justify-center mb-4">
                <div className="text-4xl">🖼️</div>
              </div>
              <h3 className="text-lg font-semibold mb-2">
                Aucun média sélectionné
              </h3>
              <p className="text-sm text-muted-foreground max-w-sm">
                Sélectionnez un fichier image ou vidéo pour prévisualiser votre
                contenu ici
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title="Modifier la publication"
      className="max-w-[1400px] w-[95vw]"
      buttons={[
        {
          label: "Supprimer",
          onClick: handleDelete,
          variant: "destructive",
          className: "px-6 hover:bg-destructive/90",
          disabled: isSubmitting,
        },
        {
          label: isSubmitting ? "Enregistrement..." : "Enregistrer",
          onClick: handleSubmit,
          variant: "default",
          className: "px-6 hover:bg-primary/90",
          disabled: isSubmitting,
        },
      ]}
    >
      {modalContent}
    </Modal>
  );
}

export default EditPostModal;
