import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { createPost } from '@/app/reducers/PostsReducer';
import { AppDispatch, RootState } from '@/app/store';
import Modal from '@/components/shared/Modal';
import { Form, Field } from 'react-final-form';
import { motion, AnimatePresence } from 'framer-motion';

interface AddPostModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function AddPostModal({ isOpen, onClose }: AddPostModalProps) {
  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useSelector((state: RootState) => state.posts.isLoading);
  const error = useSelector((state: RootState) => state.posts.error);
  const [showError, setShowError] = useState(false);
  const [preview, setPreview] = useState<string | null>(null);
  const [formRef, setFormRef] = useState<any>(null);
  const [step, setStep] = useState(1);
  const totalSteps = 3;

  const isMobile = window.innerWidth <= 768;

  const resetStates = () => {
    setShowError(false);
    setPreview(null);
    setFormRef(null);
    setStep(1);
    if (formRef) {
      formRef.reset();
    }
  };

  const slideVariants = {
    enter: (direction: number) => ({
      x: direction > 0 ? 50 : -50,
      opacity: 0
    }),
    center: {
      x: 0,
      opacity: 1
    },
    exit: (direction: number) => ({
      x: direction < 0 ? 50 : -50,
      opacity: 0
    })
  };

  const slideTransition = {
    duration: 0.2,
    ease: [0.32, 0.72, 0, 1]
  };

  const renderStep = (values: any) => {
    const direction = step === 1 ? 1 : -1;

    switch (step) {
      case 1:
        return (
          <motion.div
            key="step1"
            custom={direction}
            variants={slideVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={slideTransition}
          >
            <div>
              <Label htmlFor="title">Titre</Label>
              <Field name="title">
                {({ input }) => (
                  <Input {...input} id="title" required />
                )}
              </Field>
            </div>
          </motion.div>
        );
      case 2:
        return (
          <motion.div
            key="step2"
            custom={direction}
            variants={slideVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={slideTransition}
          >
            <div>
              <Label htmlFor="price">Prix</Label>
              <Field name="price" parse={value => parseFloat(value)}>
                {({ input }) => (
                  <div className="space-y-2">
                    <div className="relative mt-2">
                      <span className="absolute left-3 top-1/2 -translate-y-1/2 text-muted-foreground">
                        €
                      </span>
                      <Input
                        {...input}
                        id="price"
                        type="number"
                        step="0.01"
                        required
                        className="h-12 text-lg font-medium pl-7 pb-2"
                        placeholder="0.00"
                      />
                    </div>
                    <div className="flex items-center gap-2 text-sm text-muted-foreground">
                      <span>🔍</span>
                      <span>
                        Une commission de 10% sera prélevée sur le montant final
                      </span>
                    </div>
                  </div>
                )}
              </Field>
            </div>
          </motion.div>
        );
      case 3:
        return (
          <motion.div
            key="step3"
            custom={direction}
            variants={slideVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={slideTransition}
          >
            <div className="space-y-8">
              <div className="p-6 bg-muted/30 rounded-lg border border-border/50 space-y-6">
                <div>
                  <Label htmlFor="title" className="text-base font-semibold">
                    Titre de la publication
                  </Label>
                  <Field name="title">
                    {({ input }) => (
                      <Input
                        {...input}
                        id="title"
                        required
                        className="mt-2 h-12 text-lg font-medium"
                        placeholder="Entrez le titre de votre publication"
                      />
                    )}
                  </Field>
                </div>

                <div>
                  <Label htmlFor="price" className="text-base font-semibold">
                    Prix
                  </Label>
                  <Field name="price" parse={value => parseFloat(value)}>
                    {({ input }) => (
                      <div className="space-y-2">
                        <div className="relative mt-2">
                          <span className="absolute left-3 top-1/2 -translate-y-1/2 text-muted-foreground">
                            €
                          </span>
                          <Input
                            {...input}
                            id="price"
                            type="number"
                            step="0.01"
                            required
                            className="h-12 text-lg font-medium pl-7 pb-2"
                            placeholder="0.00"
                          />
                        </div>
                        <div className="flex items-center gap-2 text-sm text-muted-foreground">
                          <span>🔍</span>
                          <span>
                            Une commission de 10% sera prélevée sur le montant final
                          </span>
                        </div>
                      </div>
                    )}
                  </Field>
                </div>
              </div>

              <div className="space-y-4">
                <Label htmlFor="image" className="text-base font-semibold">
                  Média de la publication
                </Label>
                <Field name="image">
                  {({ input: { value, onChange, ...input } }) => (
                    <div className="space-y-2">
                      <Input
                        {...input}
                        id="image"
                        type="file"
                        accept="image/*,video/*"
                        onChange={(e) => {
                          handleFileChange(e);
                          onChange(e.target.files?.[0]);
                        }}
                        required
                        className="h-12 file:h-full file:bg-muted file:border-0 file:text-primary
                                  hover:file:bg-muted/80 file:font-medium cursor-pointer"
                      />
                      <p className="text-sm text-muted-foreground">
                        Format recommandé : JPG, PNG, GIF, MP4 ou MOV jusqu'à 100MB
                      </p>
                    </div>
                  )}
                </Field>
              </div>
            </div>
          </motion.div>
        );
      default:
        return null;
    }
  };

  const onSubmit = async (values: any) => {
    setShowError(false);
    const formData = new FormData();
    Object.keys(values).forEach(key => {
      formData.append(key, values[key]);
    });

    try {
      await dispatch(createPost(formData)).unwrap();
      resetStates();
      onClose();
    } catch {
      setShowError(true);
    }
  };

  const handleClose = () => {
    resetStates();
    onClose();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const newFormData = new FormData();
      newFormData.set('image', file);

      if (file.type.startsWith('video/')) {
        setPreview(URL.createObjectURL(file));
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview(reader.result as string);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const modalContent = (
    <Form
      onSubmit={onSubmit}
      initialValues={{ status: 'active' }}
      render={({ handleSubmit, form, values }) => {
        if (!formRef) setFormRef(form);
        return (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className={`
              ${isMobile
                ? 'flex flex-col h-full'
                : `flex gap-8 ${step === 3 ? 'h-[600px]' : 'h-[400px]'}`
              }
            `}>
              {/* Contenu adapté pour mobile */}
              <div className={`
                overflow-y-auto pr-4 px-2
                ${isMobile
                  ? 'w-full flex-1'
                  : step === 3 ? 'flex-[1.2]' : 'w-full'
                }
              `}>
                {showError && (
                  <div className="text-red-500 text-sm">{error}</div>
                )}

                <div className="mb-4">
                  <div className="flex justify-between mb-2">
                    {Array.from({ length: totalSteps }).map((_, idx) => (
                      <motion.div
                        key={idx}
                        className={`h-2 rounded-full ${idx + 1 <= step ? 'bg-primary' : 'bg-gray-200'}`}
                        style={{ width: `${100 / totalSteps - 2}%` }}
                        initial={{ scaleX: 0 }}
                        animate={{ scaleX: 1 }}
                        transition={{ duration: 0.3 }}
                      />
                    ))}
                  </div>
                </div>

                <AnimatePresence mode="wait">
                  {renderStep(values)}
                </AnimatePresence>

                {step !== 3 && (
                  <div className="flex justify-between mt-4">
                    <button
                      type="button"
                      onClick={() => setStep(step - 1)}
                      disabled={step === 1}
                      className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                    >
                      Précédent
                    </button>
                    <button
                      type="button"
                      onClick={() => setStep(step + 1)}
                      className="px-4 py-2 text-sm font-medium text-white bg-primary rounded-md hover:bg-primary/90"
                    >
                      Suivant
                    </button>
                  </div>
                )}
              </div>

              {/* Prévisualisation pour mobile */}
              {step === 3 && isMobile && preview && (
                <motion.div
                  className="mt-4 mb-4"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  <div className="w-full h-[200px] border-2 border-dashed rounded-xl bg-muted/50 overflow-hidden">
                    <img
                      src={preview}
                      alt="Prévisualisation"
                      className="w-full h-full object-contain p-2"
                    />
                  </div>
                </motion.div>
              )}

              {/* Prévisualisation desktop */}
              {step === 3 && !isMobile && (
                <motion.div className="flex-[2] border-l border-border pl-8">
                  <div
                    className="h-full flex items-center justify-center border-2 border-dashed rounded-xl bg-muted/50"
                    role="img"
                    aria-label={preview ? "Prévisualisation de l'image" : "Aucune image sélectionnée"}
                  >
                    {preview ? (
                      <div className="relative w-full h-full">
                        {preview.startsWith('blob:') ? (
                          <video
                            src={preview}
                            controls
                            className="max-w-full h-full object-contain rounded-lg p-2 transition-opacity duration-300"
                          />
                        ) : (
                          <img
                            src={preview}
                            alt="Prévisualisation"
                            className="max-w-full h-full object-contain rounded-lg p-2 transition-opacity duration-300"
                          />
                        )}
                      </div>
                    ) : (
                      <div className="text-base text-muted-foreground text-center p-8">
                        <div className="mb-4 text-4xl">🖼️</div>
                        <div className="font-medium mb-2">Aucun média sélectionné</div>
                        <div className="text-sm">Format recommandé : 1200x800px pour les images</div>
                        <div className="text-xs text-muted-foreground mt-2">JPG, PNG, GIF, MP4 ou MOV jusqu'à 100MB</div>
                      </div>
                    )}
                  </div>
                </motion.div>
              )}
            </div>
          </form>
        );
      }}
    />
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title="Créer une nouvelle publication"
      className={`
        transition-all duration-300 ease-in-out
        ${isMobile
          ? 'w-full h-full max-h-[100vh] m-0'
          : step === 3
            ? 'max-w-[1200px] w-[90vw]'
            : 'w-[600px] h-[275px]'
        }
      `}
      buttons={step === totalSteps ? [
        {
          label: 'Annuler',
          onClick: handleClose,
          variant: 'outline' as const,
          disabled: isLoading,
          className: isMobile ? 'w-full' : ''
        },
        {
          label: isLoading ? 'Création en cours...' : 'Créer la publication',
          onClick: () => formRef?.submit(),
          variant: 'default' as const,
          disabled: isLoading,
          className: isMobile ? 'w-full mt-2' : ''
        }
      ] : []}
    >
      <motion.div
        layout
        transition={{
          layout: { duration: 0.3 }
        }}
      >
        {modalContent}
      </motion.div>
    </Modal>
  );
}

export default AddPostModal;
