'use client'

import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { UserPlus, Mail, Lock, User, AlertCircle, ArrowLeft } from "lucide-react"
import { Link, useNavigate } from "react-router-dom"
import { Form, Field } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { register } from '../../../app/reducers/AuthReducers'
import { AppDispatch, RootState } from '../../../app/store'
import background_login_mountain from '@/assets/background_login_mountain.png'
import { useState } from 'react'

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  terms: boolean;
}

interface RegisterResponse {
  status: string;
}

export function PrettySignup() {
  const dispatch = useDispatch<AppDispatch>()
  const { error } = useSelector((state: RootState) => state.auth)
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (values: FormValues) => {
    try {
      setIsLoading(true);
      const result = await dispatch(register({
        name: values.firstName + " " + values.lastName,
        password: values.password,
        email: values.email
      })).unwrap() as RegisterResponse;

      if (result.status === "ok") {
        navigate('/dashboard');
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="min-h-screen flex flex-col md:flex-row">
      <div className="h-32 md:hidden bg-cover bg-center relative">
        <div className="absolute inset-0 bg-black/40 flex items-center justify-center">
          <h1 className="text-2xl font-bold text-white">Picke.me</h1>
        </div>
        <Link
          to="/"
          className="absolute top-4 right-4 bg-white/20 hover:bg-white/30 text-white px-4 py-2 rounded-md backdrop-blur-sm transition-all flex items-center gap-2"
        >
          <ArrowLeft className="h-4 w-4" />
          Retour au site
        </Link>
        <div
          className="absolute inset-0 bg-cover bg-center -z-10"
          style={{
            backgroundImage: `url(${background_login_mountain})`
          }}
        />
      </div>

      <div className="w-full md:w-1/2 flex items-center justify-center bg-background p-6 md:p-12">
        <div className="w-full max-w-md space-y-6 md:space-y-8">
          <div className="flex flex-col items-center space-y-4">
            <div className="bg-primary/10 p-3 rounded-full">
              <UserPlus className="h-8 w-8 text-primary" />
            </div>
            <h1 className="text-3xl font-bold text-gray-900">Créer un compte</h1>
            <p className="text-gray-600 text-center">
              Inscrivez-vous pour commencer
            </p>
          </div>

          {error && (
            <div className="p-4 bg-red-50 border-l-4 border-red-500 text-red-700 rounded-md flex items-center">
              <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
              <span className="text-sm">{error}</span>
            </div>
          )}

          <Form<FormValues>
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-2 gap-4">
                  <Field name="firstName">
                    {({ input, meta }) => (
                      <div className="space-y-2">
                        <Label htmlFor="firstName" className="text-sm font-medium text-gray-700">
                          Prénom
                        </Label>
                        <div className="relative">
                          <Input
                            {...input}
                            id="firstName"
                            placeholder="Jean"
                            className="pl-10 w-full bg-white shadow-sm text-gray-900"
                            required
                          />
                          <User className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                        </div>
                      </div>
                    )}
                  </Field>

                  <Field name="lastName">
                    {({ input, meta }) => (
                      <div className="space-y-2">
                        <Label htmlFor="lastName" className="text-sm font-medium text-gray-700">
                          Nom
                        </Label>
                        <div className="relative">
                          <Input
                            {...input}
                            id="lastName"
                            placeholder="Dupont"
                            className="pl-10 w-full bg-white shadow-sm text-gray-900"
                            required
                          />
                          <User className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                        </div>
                      </div>
                    )}
                  </Field>
                </div>

                <Field name="email">
                  {({ input, meta }) => (
                    <div className="space-y-2">
                      <Label htmlFor="email" className="text-sm font-medium text-gray-700">
                        Adresse e-mail
                      </Label>
                      <div className="relative">
                        <Input
                          {...input}
                          id="email"
                          type="email"
                          placeholder="jean.dupont@exemple.com"
                          className="pl-10 w-full bg-white shadow-sm text-gray-900"
                          required
                        />
                        <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                      </div>
                    </div>
                  )}
                </Field>

                <Field name="password">
                  {({ input, meta }) => (
                    <div className="space-y-2">
                      <Label htmlFor="password" className="text-sm font-medium text-gray-700">
                        Mot de passe
                      </Label>
                      <div className="relative">
                        <Input
                          {...input}
                          id="password"
                          type="password"
                          placeholder="Entrez votre mot de passe"
                          className="pl-10 w-full bg-white shadow-sm text-gray-900"
                          required
                        />
                        <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                      </div>
                    </div>
                  )}
                </Field>

                <Field name="terms" type="checkbox">
                  {({ input }) => (
                    <div className="flex items-center">
                      <input
                        {...input}
                        id="terms"
                        type="checkbox"
                        className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
                        required
                      />
                      <Label htmlFor="terms" className="ml-2 text-sm text-gray-600">
                        J'accepte les{" "}
                        <Link to="/terms" className="font-medium text-primary hover:text-primary/80">
                          conditions d'utilisation
                        </Link>
                      </Label>
                    </div>
                  )}
                </Field>

                <Button
                  type="submit"
                  className="w-full py-2.5 font-medium transition-all duration-200 hover:shadow-lg"
                  disabled={isLoading}
                >
                  {isLoading ? 'Inscription...' : 'S\'inscrire'}
                </Button>
              </form>
            )}
          />

          <hr className="border-border" />

          <div className="text-center">
            <p className="text-sm text-foreground">
              Vous avez déjà un compte ?{" "}
              <Link
                to="/login"
                className="font-medium text-primary hover:text-primary/80 transition-colors"
              >
                Se connecter
              </Link>
            </p>
          </div>
        </div>
      </div>

      <div className="hidden md:block md:w-1/2 md:fixed md:right-0 md:top-0 md:h-full relative">
        <Link
          to="/"
          className="absolute top-4 right-4 z-10 bg-white/20 hover:bg-white/30 text-white px-4 py-2 rounded-md backdrop-blur-sm transition-all flex items-center gap-2"
        >
          <ArrowLeft className="h-4 w-4" />
          Retour au site
        </Link>
        <div className="absolute inset-0 bg-black/50 flex flex-col items-center justify-end text-white p-8 pb-16">
          <h2 className="text-3xl font-bold mb-4">Rejoignez la communauté</h2>
          <p className="text-xl text-center">Créez, partagez et monétisez votre contenu</p>
        </div>
        <div
          className="absolute inset-0 bg-cover bg-center -z-10"
          style={{
            backgroundImage: `url(${background_login_mountain})`
          }}
        />
      </div>
    </div>
  )
}
