"use client";

import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Pencil, Check, X, User2, Mail, Camera, Shield } from "lucide-react";
import { Input } from "@/components/ui/input";
import { useSelector } from "react-redux";
import { RootState } from "@/app/store";
import { User } from "../types/user";
import { motion } from "framer-motion";

export function ProfileForm() {
  const user = useSelector((state: RootState) => state.auth.user as User);
  const [editedUser, setEditedUser] = useState<User>(user);

  const [editingSection, setEditingSection] = useState<
    "personalInfo" | null
  >(null);

  const handleEdit = (section: "personalInfo") => {
    setEditingSection(section);
  };

  const handleSave = () => {
    setEditingSection(null);
    // Logique pour sauvegarder les modifications
  };

  const handleCancel = () => {
    setEditingSection(null);
    setEditedUser(user);
  };

  const renderEditableField = (
    section: "personalInfo",
    key: keyof User,
    value: string | null
  ) => {
    if (editingSection === section) {
      return (
        <Input
          value={value || ""}
          onChange={(e) => {
            setEditedUser((prev) => ({
              ...prev,
              [key]: e.target.value,
            }));
          }}
        />
      );
    }
    return <p>{value || "N/A"}</p>;
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
    >
      <Card className="w-full mx-auto hover:shadow-lg transition-shadow">
        <CardHeader>
          <div className="flex items-center gap-2">
            <User2 className="w-6 h-6 text-primary" />
            <CardTitle className="text-2xl font-semibold bg-gradient-to-r from-primary to-primary/60 bg-clip-text text-transparent">
              Paramètres du compte
            </CardTitle>
          </div>
        </CardHeader>
        <CardContent className="space-y-6">
          <motion.div
            whileHover={{ scale: 1.01 }}
            className="flex items-center space-x-4 p-4 border rounded-lg bg-card hover:shadow-md transition-all"
          >
            <div className="relative group">
            <Avatar className="w-20 h-20">
            <AvatarFallback>
              {user.name
                .split(" ")
                .map((n) => n[0])
                .join("")}
            </AvatarFallback>
          </Avatar>
              <div className="absolute inset-0 flex items-center justify-center bg-black/40 rounded-full opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer">
                <Camera className="w-6 h-6 text-white" />
              </div>
            </div>
            <div>
              <h2 className="text-2xl font-semibold text-primary">{user.name}</h2>
              <div className="flex items-center gap-1 text-sm text-muted-foreground">
                <Shield className="w-4 h-4" />
                <span>{user.status}</span>
              </div>
              <div className="flex items-center gap-1 text-sm text-muted-foreground">
                <Mail className="w-4 h-4" />
                <span>{user.email}</span>
              </div>
            </div>
          </motion.div>

          <motion.div
            whileHover={{ scale: 1.01 }}
            className="space-y-4 p-4 border rounded-lg hover:shadow-md transition-all"
          >
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-semibold text-primary">Informations personnelles</h3>
              {editingSection === "personalInfo" ? (
                <div className="flex gap-2">
                  <Button variant="default" size="sm" onClick={handleSave}>
                    <Check className="w-4 h-4 mr-2" />
                    Sauvegarder
                  </Button>
                  <Button variant="outline" size="sm" onClick={handleCancel}>
                    <X className="w-4 h-4 mr-2" />
                    Annuler
                  </Button>
                </div>
              ) : (
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => handleEdit("personalInfo")}
                  className="hover:bg-primary/10"
                >
                  <Pencil className="w-4 h-4 mr-2" />
                  Modifier
                </Button>
              )}
            </div>
            <div className="grid grid-cols-2 gap-4">
              {["name", "email"].map((key) => (
                <div key={key} className="space-y-2">
                  <label className="text-sm font-medium text-muted-foreground flex items-center gap-2">
                    {key === "name" ? <User2 className="w-4 h-4" /> : <Mail className="w-4 h-4" />}
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </label>
                  <div className="transition-all">
                    {renderEditableField(
                      "personalInfo",
                      key as keyof User,
                      editedUser[key as keyof User] as string | null
                    )}
                  </div>
                </div>
              ))}
            </div>
          </motion.div>
        </CardContent>
      </Card>
    </motion.div>
  );
}
