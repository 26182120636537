import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '@/app/middlewares/ReduxMiddlewares';
import { REACT_APP_API_URL } from '../../config';

interface WithdrawState {
  status: 'idle' | 'pending' | 'success' | 'failed';
  amount: number | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: WithdrawState = {
  status: 'idle',
  amount: null,
  isLoading: false,
  error: null,
};

export const createWithdraw = createAsyncThunk(
  'withdraw/createWithdraw',
  async (amount: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`${REACT_APP_API_URL}/sales/withdraw`, {
        amount
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Erreur lors du retrait');
    }
  }
);

const withdrawSlice = createSlice({
  name: 'withdraw',
  initialState,
  reducers: {
    resetWithdraw: (state) => {
      state.status = 'idle';
      state.amount = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createWithdraw.pending, (state) => {
        state.isLoading = true;
        state.status = 'pending';
        state.error = null;
      })
      .addCase(createWithdraw.fulfilled, (state, action) => {
        state.isLoading = false;
        state.status = 'success';
        state.amount = action.payload.amount;
      })
      .addCase(createWithdraw.rejected, (state, action) => {
        state.isLoading = false;
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { resetWithdraw } = withdrawSlice.actions;
export default withdrawSlice.reducer;
