'use client'

import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Lock, Mail, AlertCircle, ArrowLeft } from "lucide-react"
import { Form, Field } from 'react-final-form'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '@/app/reducers/AuthReducers'
import { AppDispatch, RootState } from '@/app/store' // Assurez-vous que ce chemin est correct
import background_login_mountain from '@/assets/background_login_mountain.png'
import { useState, useEffect } from 'react'


interface FormValues {
  email: string;
  password: string;
  rememberMe: boolean;
}

export function PrettyLogin() {
  const dispatch = useDispatch<AppDispatch>()
  const { error } = useSelector((state: RootState) => state.auth)
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    "Monétisez votre contenu en toute simplicité",
    "Gérez vos abonnements en un clic",
    "Développez votre communauté"
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 3000);
    return () => clearInterval(timer);
  }, []);

  const onSubmit = async (values: FormValues) => {
    try {
      setIsLoading(true);
      const result = await dispatch(login({ email: values.email, password: values.password })).unwrap()
      if (result.message === "Connexion réussie") {
        navigate('/dashboard');
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="min-h-screen flex flex-col md:flex-row">
      <div className="h-32 md:hidden bg-cover bg-center relative">
        <div className="absolute inset-0 bg-black/40 flex items-center justify-center">
          <h1 className="text-2xl font-bold text-white">Picke.me</h1>
        </div>
        <Link
          to="/"
          className="absolute top-4 right-4 bg-white/20 hover:bg-white/30 text-white px-4 py-2 rounded-md backdrop-blur-sm transition-all"
        >
          Retour au site
        </Link>
        <div
          className="absolute inset-0 bg-cover bg-center -z-10"
          style={{
            backgroundImage: `url(${background_login_mountain})`
          }}
        />
      </div>

      <div className="w-full md:w-1/2 flex items-center justify-center bg-background p-6 md:p-12">
        <div className="w-full max-w-md space-y-6 md:space-y-8">
          <div className="flex flex-col items-center space-y-4">
            <div className="bg-primary/10 p-3 rounded-full">
              <Lock className="h-8 w-8 text-primary" />
            </div>
            <h1 className="text-3xl font-bold text-foreground">Bienvenue sur Picke.me</h1>
            <p className="text-muted-foreground text-center">
              Connectez-vous pour gérer et monétiser votre contenu
            </p>
          </div>

          {error && (
            <div className="p-4 bg-red-50 border-l-4 border-red-500 text-red-700 rounded-md flex items-center">
              <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
              <span className="text-sm">{error}</span>
            </div>
          )}

          <Form<FormValues>
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className="space-y-6">
                <Field name="email">
                  {({ input, meta }) => (
                    <div className="space-y-2">
                      <Label htmlFor="email" className="text-sm font-medium text-foreground">
                        Adresse email
                      </Label>
                      <div className="relative">
                        <Input
                          {...input}
                          id="email"
                          type="email"
                          placeholder="nom@exemple.com"
                          className="pl-10 w-full bg-background text-foreground shadow-sm"
                          required
                        />
                        <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-muted-foreground" />
                      </div>
                      {meta.touched && meta.error &&
                        <span className="text-red-500 text-xs">{meta.error}</span>
                      }
                    </div>
                  )}
                </Field>

                <Field name="password">
                  {({ input, meta }) => (
                    <div className="space-y-2">
                      <Label htmlFor="password" className="text-sm font-medium text-foreground">
                        Mot de passe
                      </Label>
                      <div className="relative">
                        <Input
                          {...input}
                          id="password"
                          type="password"
                          placeholder="••••••••"
                          className="pl-10 w-full bg-background text-foreground shadow-sm"
                          required
                        />
                        <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-muted-foreground" />
                      </div>
                      {meta.touched && meta.error &&
                        <span className="text-red-500 text-xs">{meta.error}</span>
                      }
                    </div>
                  )}
                </Field>

                <div className="flex items-center justify-between">
                  <Field name="rememberMe" type="checkbox">
                    {({ input }) => (
                      <div className="flex items-center">
                        <input
                          {...input}
                          id="remember-me"
                          type="checkbox"
                          className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
                        />
                        <Label htmlFor="remember-me" className="ml-2 text-sm text-foreground">
                          Se souvenir de moi
                        </Label>
                      </div>
                    )}
                  </Field>
                  <Link
                    to="/forgot-password"
                    className="text-sm font-medium text-primary hover:text-primary/80 transition-colors"
                  >
                    Mot de passe oublié ?
                  </Link>
                </div>

                <Button
                  type="submit"
                  className="w-full py-2.5 font-medium transition-all duration-200 hover:shadow-lg"
                  disabled={isLoading}
                >
                  {isLoading ? 'Connexion...' : 'Se connecter'}
                </Button>
              </form>
            )}
          />

          <hr className="border-border" />

          <div className="text-center">
            <p className="text-sm text-foreground">
              Vous n'avez pas de compte ?{" "}
              <Link
                to="/register"
                className="font-medium text-primary hover:text-primary/80 transition-colors"
              >
                Créer un compte
              </Link>
            </p>
          </div>
        </div>
      </div>

      <div className="hidden md:block md:w-1/2 md:fixed md:right-0 md:top-0 md:h-full relative">
        <Link
          to="/"
          className="absolute top-4 right-4 z-10 bg-white/20 hover:bg-white/30 text-white px-4 py-2 rounded-md backdrop-blur-sm transition-all flex items-center gap-2"
        >
          <ArrowLeft className="h-4 w-4" />
          Retour au site
        </Link>
        <div className="absolute inset-0 bg-black/50 flex flex-col items-center justify-end text-white p-8 pb-16">
          <div className="relative h-8 mb-4 w-full">
            {slides.map((text, index) => (
              <p
                key={index}
                className={`absolute w-full text-xl text-center font-bold transition-all duration-500 ${
                  index === currentSlide
                    ? 'opacity-100 transform translate-y-0'
                    : 'opacity-0 transform translate-y-4'
                }`}
              >
                {text}
              </p>
            ))}
          </div>
          <div className="flex gap-2">
            {slides.map((_, index) => (
              <div
                key={index}
                className={`h-2 w-2 rounded-full transition-all ${
                  index === currentSlide ? 'bg-white' : 'bg-white/50'
                }`}
              />
            ))}
          </div>
        </div>

        <div className="absolute inset-0 bg-cover bg-center -z-10"
          style={{
            backgroundImage: `url(${background_login_mountain})`
          }}
        />
      </div>
    </div>
  )
}
