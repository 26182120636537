import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from '@/app/middlewares/ReduxMiddlewares';
import { REACT_APP_API_URL } from '../../config';

interface PaymentState {
  sessionId: string | null;
  paymentStatus: 'idle' | 'pending' | 'success' | 'failed';
  error: string | null;
}

const initialState: PaymentState = {
  sessionId: null,
  paymentStatus: 'idle',
  error: null,
};

export const createCheckoutSession = createAsyncThunk(
  'payment/createCheckoutSession',
  async ({ postId, price }: { postId: number; price: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`${REACT_APP_API_URL}/payments/create-checkout-session`, {
        postId,
        price
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Erreur lors de la création de la session de paiement');
    }
  }
);

export const checkSessionStatus = createAsyncThunk(
  'payment/checkSessionStatus',
  async (sessionId: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`${REACT_APP_API_URL}/payments/${sessionId}/status`);
      return {
        status: response.data.status,
        metadata: response.data.metadata,
        sessionId: response.data.sessionId
      };
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Erreur lors de la vérification du statut');
    }
  }
);

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    resetPaymentStatus: (state) => {
      state.paymentStatus = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Create Checkout Session
      .addCase(createCheckoutSession.pending, (state) => {
        state.paymentStatus = 'pending';
        state.error = null;
      })
      .addCase(createCheckoutSession.fulfilled, (state, action) => {
        state.paymentStatus = 'success';
        state.sessionId = action.payload.sessionId;
      })
      .addCase(createCheckoutSession.rejected, (state, action) => {
        state.paymentStatus = 'failed';
        state.error = action.payload as string;
      })
      // Check Session Status
      .addCase(checkSessionStatus.pending, (state) => {
        state.paymentStatus = 'pending';
        state.error = null;
      })
      .addCase(checkSessionStatus.fulfilled, (state, action) => {
        state.paymentStatus = action.payload.status === 'paid' ? 'success' : 'pending';
      })
      .addCase(checkSessionStatus.rejected, (state, action) => {
        state.paymentStatus = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { resetPaymentStatus } = paymentSlice.actions;
export default paymentSlice.reducer;
