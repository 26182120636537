import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { ComposedChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { motion } from "framer-motion";

interface SalesAnalytics {
  name: string;
  ventes: number;
  montant: number;
  date: string;
}

interface SalesChartProps {
  data: SalesAnalytics[];
}

export const SalesChart = ({ data }: SalesChartProps) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.2 }}
    >
      <Card>
        <CardHeader>
          <CardTitle>Analytiques des ventes</CardTitle>
          <CardDescription>
            Visualisez le nombre de ventes par publication
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="h-[400px] w-full">
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                data={data}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 60,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" className="stroke-muted" />
                <XAxis
                  dataKey="name"
                  angle={-45}
                  textAnchor="end"
                  height={60}
                  interval={0}
                  className="text-xs text-foreground"
                  tick={{ fill: 'var(--foreground)' }}
                />
                <YAxis
                  yAxisId="left"
                  orientation="left"
                  label={{
                    value: 'Nombre de ventes',
                    angle: -90,
                    position: 'insideLeft',
                    fill: 'var(--foreground)',
                  }}
                  tick={{ fill: 'var(--foreground)' }}
                />
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  label={{
                    value: 'Montant (€)',
                    angle: 90,
                    position: 'insideRight',
                    fill: 'var(--foreground)',
                  }}
                  tick={{ fill: 'var(--foreground)' }}
                />
                <Tooltip
                  contentStyle={{
                    backgroundColor: 'var(--background)',
                    border: '1px solid var(--border)',
                    borderRadius: '6px',
                    color: 'var(--foreground)',
                  }}
                />
                <Legend
                  wrapperStyle={{
                    color: 'var(--foreground)'
                  }}
                />
                <Bar
                  yAxisId="left"
                  dataKey="ventes"
                  name="Nombre de ventes"
                  fill="hsl(var(--primary))"
                  radius={[4, 4, 0, 0]}
                  barSize={20}
                />
                <Line
                  yAxisId="right"
                  type="monotone"
                  dataKey="montant"
                  name="Montant total (€)"
                  stroke="hsl(var(--primary))"
                  strokeWidth={2}
                  dot={{
                    r: 4,
                    fill: 'hsl(var(--background))',
                    stroke: 'hsl(var(--primary))',
                    strokeWidth: 2
                  }}
                  activeDot={{
                    r: 6,
                    fill: 'hsl(var(--background))',
                    stroke: 'hsl(var(--primary))',
                    strokeWidth: 2
                  }}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </CardContent>
      </Card>
    </motion.div>
  );
};
