import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from '@/app/middlewares/ReduxMiddlewares';
import { REACT_APP_API_URL } from '../../config';
import { Post, Sale } from '@/components/services/types/posts';

interface Analytics {
  id: number;
  title: string;
  date: string;
  total_amount: number;
  total_sales: number;
  views: number;
  engagement_rate: number;
}

interface SalesStats {
  total_sales_week: number;
  total_sales_all_time: number;
  average_sales_per_post: number;
  total_views: number;
  total_purchases: number;
  engagement_rate: string;
}

interface PostsMeta {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
  first_page: number;
  analytics?: Analytics[];
  sales_stats?: SalesStats;
}

interface PostsState {
  posts: Post[];
  sales: Sale[];
  currentPost: Post | null;
  isLoading: boolean;
  error: string | string[] | null;
  currentPage: number;
  totalPages: number;
  total: number;
  perPage: number;
  salesStats?: SalesStats;
  meta?: PostsMeta;
}

const initialState: PostsState = {
  posts: [],
  sales: [],
  currentPost: null,
  isLoading: false,
  error: null,
  currentPage: 1,
  totalPages: 1,
  total: 0,
  perPage: 10,
  salesStats: undefined,
  meta: undefined,
};

// Thunks
export const fetchPosts = createAsyncThunk(
  'posts/fetchPosts',
  async ({ page, limit }: { page: number; limit: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`${REACT_APP_API_URL}/posts?page=${page}&limit=${limit}`);
      return {
        posts: response.data.data,
        meta: response.data.meta
      };
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Erreur lors de la récupération des posts');
    }
  }
);

export const fetchPostByUuid = createAsyncThunk(
  'posts/fetchByUuid',
  async ({ uuid, stripeSessionId }: { uuid: string, stripeSessionId: string | null }) => {
    const headers: Record<string, string> = {};
    if (stripeSessionId) {
      headers['X-Stripe-Session-Id'] = stripeSessionId;
    }

    console.log('uuid:', uuid)
    console.log('headers:', headers)
    const response = await axiosInstance.get(`${REACT_APP_API_URL}/posts/share/${uuid}`, { headers });
    return response.data;
  }
);

export const createPost = createAsyncThunk(
  'posts/createPost',
  async (formData: FormData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`${REACT_APP_API_URL}/posts`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Erreur lors de la création du post');
    }
  }
);

export const updatePost = createAsyncThunk(
  'posts/updatePost',
  async ({ id, formData }: { id: number; formData: FormData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`${REACT_APP_API_URL}/posts/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Erreur lors de la mise à jour du post');
    }
  }
);

export const deletePost = createAsyncThunk(
  'posts/deletePost',
  async (id: number, { rejectWithValue }) => {
    try {
      await axiosInstance.delete(`${REACT_APP_API_URL}/posts/${id}`);
      return id;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Erreur lors de la suppression du post');
    }
  }
);

const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    clearCurrentPost: (state) => {
      state.currentPost = null;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setPerPage: (state, action: PayloadAction<number>) => {
      state.perPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Posts
      .addCase(fetchPosts.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.posts = action.payload.posts;
        state.meta = action.payload.meta;
        state.totalPages = action.payload.meta.last_page;
        state.salesStats = action.payload.meta.sales_stats;
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      // Fetch Post by UUID
      .addCase(fetchPostByUuid.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchPostByUuid.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentPost = action.payload;
      })
      .addCase(fetchPostByUuid.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      // Create Post
      .addCase(createPost.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createPost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.posts.unshift(action.payload);
      })
      .addCase(createPost.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      // Update Post
      .addCase(updatePost.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updatePost.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.posts.findIndex(post => post.id === action.payload.id);
        if (index !== -1) {
          state.posts[index] = action.payload;
        }
      })
      .addCase(updatePost.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      // Delete Post
      .addCase(deletePost.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deletePost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.posts = state.posts.filter(post => post.id !== action.payload);
      })
      .addCase(deletePost.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export const { clearCurrentPost, setCurrentPage, setPerPage } = postsSlice.actions;
export default postsSlice.reducer;
