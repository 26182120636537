import React from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/app/store";
import { createWithdraw } from "@/app/reducers/WithdrawReducer";
import { toast } from "@/hooks/use-toast";
import { getWalletBalance } from "@/app/reducers/UserReducers";
import { Info } from "lucide-react";

interface ModalWithdrawProps {
  isOpen: boolean;
  onClose: () => void;
  maxAmount: number;
}

function ModalWithdraw({ isOpen, onClose, maxAmount }: ModalWithdrawProps) {
  const dispatch = useDispatch<AppDispatch>();
  const [withdrawAmount, setWithdrawAmount] = React.useState<number>(0);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const handleWithdrawSubmit = () => {
    setIsLoading(true);
    dispatch(createWithdraw(withdrawAmount))
      .unwrap()
      .then((result) => {
        toast({
          title: "Retrait effectué avec succès",
          description: "Votre retrait a été effectué avec succès.",
        });
        dispatch(getWalletBalance());
        onClose();
      })
      .catch((error) => {
        toast({
          title: "Erreur lors du retrait",
          description: "Une erreur est survenue lors du retrait de vos fonds.",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Retrait des fonds</DialogTitle>
        </DialogHeader>
        <div className="space-y-4 py-2">
          <div className="space-y-2">
            <Label htmlFor="amount">Montant à retirer</Label>
            <div className="relative">
              <span className="absolute left-3 top-1/2 -translate-y-1/2">
                €
              </span>
              <Input
                id="amount"
                type="number"
                value={withdrawAmount}
                onChange={(e) => setWithdrawAmount(Number(e.target.value))}
                className="pl-8"
                max={maxAmount}
              />
            </div>
            <p className="text-sm text-muted-foreground">
              Montant maximum disponible: {maxAmount}€
            </p>
            <DialogDescription>
              <div className="flex items-center gap-2 bg-red-100 p-2 rounded-md">
                <Info className="h-4 w-4 text-red-500" />
                <p className="text-sm text-muted-foreground text-red-500">
                  <span className="font-bold">Attention</span> cette action n'est pas obligatoire.<br/>
                  Stripe transfert chaque jour le montant intégral disponible de votre cagnotte à votre banque
                </p>
              </div>
            </DialogDescription>
          </div>
          <div className="flex justify-end space-x-2">
            <Button variant="outline" onClick={onClose} disabled={isLoading}>
              Annuler
            </Button>
            <Button onClick={handleWithdrawSubmit} disabled={isLoading}>
              {isLoading ? "Traitement en cours..." : "Confirmer le retrait"}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ModalWithdraw;
