import React from 'react';
import { Button } from '@/components/ui/button';
import { loadStripe } from '@stripe/stripe-js';
import { useToast } from "@/hooks/use-toast";
import axiosInstance from '@/app/middlewares/ReduxMiddlewares';
import { REACT_APP_API_URL } from '@/config';

const STRIPE_PUBLIC_KEY = 'pk_test_51Q6VrX2Lm56OzpiYyRd6Q3GBO1H9ueb9nAUuzZJbiQmmCLcQkVnDyIjgXDOBMCGdVKDdaGuDPTLcfvBNWH4OUoLr00xcHWwf81';
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

interface CheckoutButtonProps {
  postId: number;
  price: number;
  uuid: string;
  className?: string;
}

export function CheckoutButton({ postId, price, uuid, className }: CheckoutButtonProps) {
  const { toast } = useToast();
  const [isAlreadyPurchased, setIsAlreadyPurchased] = React.useState(false);

  React.useEffect(() => {
    const stripeData = localStorage.getItem('picke_me_stripedata');
    if (stripeData) {
      const purchases = JSON.parse(stripeData);
      const alreadyPurchased = purchases.some((purchase: any) => purchase.uuid === uuid);
      setIsAlreadyPurchased(alreadyPurchased);
    }
  }, [uuid]);

  const handleCheckout = async () => {
    if (isAlreadyPurchased) {
      toast({
        title: "Déjà acheté",
        description: "Vous avez déjà acheté ce contenu",
        variant: "default"
      });
      return;
    }
    try {
      const stripe = await stripePromise;
      if (!stripe) throw new Error('Stripe n\'a pas pu être chargé');

      const response = await axiosInstance.post(`${REACT_APP_API_URL}/payments/create-checkout-session`, {
        postId,
        price,
        uuid
      });

      const { sessionId } = response.data;

      if (!sessionId) throw new Error('Session ID non reçu');

      // Rediriger vers Checkout
      const { error } = await stripe.redirectToCheckout({
        sessionId
      });

      if (error) {
        console.error('Stripe error:', error);
        throw error;
      }
    } catch (error: any) {
      console.error('Checkout error:', error);
      toast({
        variant: "destructive",
        title: "Erreur",
        description: error.message || "Une erreur est survenue lors de l'initialisation du paiement",
      });
    }
  };

  return (
    <Button
      onClick={handleCheckout}
      className={className}
      disabled={isAlreadyPurchased}
    >
      {isAlreadyPurchased ? "Déjà acheté" : "Acheter maintenant"}
    </Button>
  );
}
