import { AppDispatch, RootState } from "@/app/store";
import React, { useEffect, useCallback, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSales } from "@/app/reducers/SalesReducer";
import { SalesTable } from "@/components/services/sales/TablesSales";
import { SalesByPost } from "@/components/services/types/sales";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { TableFilter } from "@/components/services/sales/TableFilter";
import { useSalesFilter } from "@/hooks/useSalesFilter";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardDescription,
} from "@/components/ui/card";
import { TrendingUp, Clock, Wallet, XCircle, ExternalLink, Loader2 } from "lucide-react";
import { Progress } from "@/components/ui/progress";
import { Button } from "@/components/ui/button";
import { createWithdraw } from "@/app/reducers/WithdrawReducer";
import { createStripeConnect, createStripeDashboardLink } from "@/app/reducers/UserReducers";
import { toast } from "@/hooks/use-toast";
import { getWalletBalance } from "@/app/reducers/UserReducers";
import ModalWithdraw from '@/components/services/sales/ModalWithdraw';
import { motion, AnimatePresence } from "framer-motion";
import { getUser } from "@/app/reducers/AuthReducers";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

function SalesPage() {
  const dispatch = useDispatch<AppDispatch>();
  const { sales, isLoading } = useSelector((state: RootState) => state.sales);
  const { balance } = useSelector((state: RootState) => state.users);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterStatus, setFilterStatus] = useState<string>("all");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  const [isStripeLoading, setIsStripeLoading] = useState(false);

  // Stripe Connect
  const { user } = useSelector(
    (state: RootState) => state.auth
  );

  useEffect(() => {
    dispatch(fetchSales());
    dispatch(getUser());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getWalletBalance());
  }, [dispatch]);

  const salesData = useMemo(() => {
    return sales && typeof sales === "object" ? sales : {};
  }, [sales]);

  const salesStats = useMemo(() => {
    if (!salesData)
      return {
        completed: { count: 0, total: 0 },
        pending: { count: 0, total: 0 },
        failed: { count: 0, total: 0 },
      };

    const allSales = Object.values(salesData).reduce(
      (acc: any[], postSales: any) => {
        if (postSales.sales && Array.isArray(postSales.sales)) {
          return [...acc, ...postSales.sales];
        }
        return acc;
      },
      []
    );

    const completed = allSales.filter((sale) => sale?.status === "completed");
    const pending = allSales.filter((sale) => sale?.status === "pending");
    const failed = allSales.filter((sale) => sale?.status === "failed");

    return {
      completed: {
        count: completed.length,
        total: completed.reduce(
          (sum, sale) => sum + (parseFloat(sale?.amount) || 0),
          0
        ),
      },
      pending: {
        count: pending.length,
        total: pending.reduce(
          (sum, sale) => sum + (parseFloat(sale?.amount) || 0),
          0
        ),
      },
      failed: {
        count: failed.length,
        total: failed.reduce(
          (sum, sale) => sum + (parseFloat(sale?.amount) || 0),
          0
        ),
      },
    };
  }, [salesData]);

  const { filteredSales, totalPages } = useSalesFilter(
    salesData,
    filterStatus,
    searchQuery
  );

  const handleWithdraw = async () => {
    if (user?.status !== 'active') {
      try {
        setIsStripeLoading(true);
        dispatch(createStripeConnect())
          .unwrap()
          .then((result) => {
            if (result.url) {
              window.location.href = result.url;
            }
          })
          .catch((error) => {
            console.error("Erreur lors de la création du compte Stripe:", error);
            toast({
              variant: "destructive",
              title: "Erreur",
              description: "Impossible de configurer Stripe Connect",
            });
          })
          .finally(() => {
            setIsStripeLoading(false);
          });
      } catch (error) {
        console.error("Erreur lors de la création du compte Stripe:", error);
        setIsStripeLoading(false);
        toast({
          variant: "destructive",
          title: "Erreur",
          description: "Impossible de configurer Stripe Connect",
        });
      }
    } else {
      setIsWithdrawModalOpen(true);
    }
  };

  const handleStripeDashboard = async () => {
    try {
      dispatch(createStripeDashboardLink())
        .unwrap()
        .then((result) => {
          if (result.url) {
            window.open(result.url, '_blank');
          }
        })
        .catch((error) => {
          console.error("Erreur lors de l'accès au dashboard Stripe:", error);
          toast({
            variant: "destructive",
            title: "Erreur",
            description: "Impossible d'accéder au dashboard Stripe",
          });
        });
    } catch (error) {
      console.error("Erreur lors de l'accès au dashboard Stripe:", error);
      toast({
        variant: "destructive",
        title: "Erreur",
        description: "Impossible d'accéder au dashboard Stripe",
      });
    }
  };

  const stats = [
    {
      title: "Cagnotte",
      value: `${balance?.instant_available[0]?.amount / 100}€`,
      icon: <Wallet className="w-6 h-6 text-primary" />,
      description: "Montant disponible",
      action: (
        <div className="space-y-2">
          <Button onClick={handleWithdraw} className="w-full" disabled={isStripeLoading}>
            {isStripeLoading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Configuration en cours...
              </>
            ) : (
              <>
                <Wallet className="mr-2 h-4 w-4" />
                {user?.stripeConnectStatus !== 'active' ? "Configurer Stripe Connect" : "Retirer les fonds"}
              </>
            )}
          </Button>
          {user?.stripeConnectStatus === 'active' && (
            <Button onClick={handleStripeDashboard} variant="outline" className="w-full">
              <ExternalLink className="mr-2 h-4 w-4" />
              Accéder au dashboard Stripe
            </Button>
          )}
        </div>
      )
    },
    {
      title: "Ventes Complétées",
      value: `${salesStats.completed.count}`,
      total: `${salesStats.completed.total.toFixed(2)}€`,
      icon: <TrendingUp className="w-6 h-6 text-emerald-600 dark:text-emerald-400" />,
      progressValue: salesStats.completed.count ?
        (salesStats.completed.count / (salesStats.completed.count + salesStats.pending.count)) * 100 : 0,
      progressClass: "bg-emerald-100 dark:bg-emerald-900"
    },
    {
      title: "En Attente",
      value: `${salesStats.pending.count}`,
      total: `${salesStats.pending.total.toFixed(2)}€`,
      icon: <Clock className="w-6 h-6 text-yellow-600 dark:text-yellow-400" />,
      progressValue: salesStats.pending.count ?
        (salesStats.pending.count / (salesStats.completed.count + salesStats.pending.count)) * 100 : 0,
      progressClass: "bg-yellow-100 dark:bg-yellow-900 [&>div]:bg-yellow-600 dark:[&>div]:bg-yellow-400"
    },
    {
      title: "Refusées",
      value: `${salesStats.failed.count}`,
      total: `${salesStats.failed.total.toFixed(2)}€`,
      icon: <XCircle className="w-6 h-6 text-rose-600 dark:text-rose-400" />,
      progressValue: salesStats.failed.count ?
        (salesStats.failed.count / (salesStats.completed.count + salesStats.failed.count)) * 100 : 0,
      progressClass: "bg-rose-100 dark:bg-rose-900 [&>div]:bg-rose-600 dark:[&>div]:bg-rose-400"
    }
  ];

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="container mx-auto p-6 space-y-8"
      >
        {/* En-tête */}
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          className="flex justify-between items-center"
        >
          <h1 className="text-4xl font-bold bg-gradient-to-r from-primary to-primary/60 bg-clip-text text-transparent">
            Mes Ventes
          </h1>
        </motion.div>

        {/* Grille de statistiques */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {stats.map((stat, index) => (
            <motion.div
              key={stat.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <Card className="hover:shadow-lg transition-all duration-200">
                <CardHeader className="pb-2">
                  <div className="flex items-center justify-between">
                    {stat.icon}
                    <CardTitle className="text-xl">{stat.title}</CardTitle>
                  </div>
                  <div className="mt-2">
                    <div className="text-3xl font-bold">{stat.value}</div>
                    {stat.total && (
                      <CardDescription>Total: {stat.total}</CardDescription>
                    )}
                  </div>
                </CardHeader>
                <CardContent>
                  {stat.action}
                  {stat.progressValue !== undefined && (
                    <Progress
                      value={stat.progressValue}
                      className={`h-2 ${stat.progressClass}`}
                    />
                  )}
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </div>

        {/* Tableau des ventes */}
        <Tabs defaultValue="historique" className="space-y-6">
          <TabsList className="grid w-full grid-cols-1 lg:w-[400px]">
            <TabsTrigger value="historique">Historique</TabsTrigger>
          </TabsList>

          <TabsContent value="historique">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              <Card>
                <CardHeader>
                  <div className="flex items-center gap-2">
                    <ExternalLink className="h-5 w-5 text-primary" />
                    <CardTitle>Historique des ventes</CardTitle>
                  </div>
                  <CardDescription>
                    Gérez et suivez toutes vos transactions
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <TableFilter
                    searchQuery={searchQuery}
                    filterStatus={filterStatus}
                    onSearchChange={setSearchQuery}
                    onStatusChange={setFilterStatus}
                  />
                  <SalesTable
                    salesData={Object.fromEntries(filteredSales) as unknown as SalesByPost}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={setCurrentPage}
                  />
                </CardContent>
              </Card>
            </motion.div>
          </TabsContent>

          <TabsContent value="analytics">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              <Card>
                <CardHeader>
                  <CardTitle>Analytiques des ventes</CardTitle>
                  <CardDescription>
                    Visualisez vos performances de vente
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <div className="h-[300px] flex items-center justify-center text-muted-foreground">
                    Graphiques à venir
                  </div>
                </CardContent>
              </Card>
            </motion.div>
          </TabsContent>
        </Tabs>

        <ModalWithdraw
          isOpen={isWithdrawModalOpen}
          onClose={() => setIsWithdrawModalOpen(false)}
          maxAmount={balance?.instant_available[0]?.amount / 100}
        />
      </motion.div>
    </AnimatePresence>
  );
}

export default SalesPage;
