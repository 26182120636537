import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import { TooltipProvider } from "@/components/ui/tooltip";
import { PostsTable } from "@/components/services/posts/TablesPosts";
import { fetchPosts, setCurrentPage } from "@/app/reducers/PostsReducer";
import { AppDispatch, RootState } from "@/app/store";
import { Post } from "@/components/services/types/posts";
import { ThemeToggle } from "@/components/shared/ThemeToggle";
import AddPostModal from "@/components/services/posts/AddPostModal";
import EditPostModal from "@/components/services/posts/EditPostModal";
import { motion, AnimatePresence } from "framer-motion";
import { LineChart as LineChartIcon, Wallet, TrendingUp, Users, Activity } from "lucide-react";
import { ComposedChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { SalesChart } from "@/components/services/posts/SalesCharts";


const DashboardPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { posts, currentPage, totalPages, perPage, salesStats } = useSelector(
    (state: RootState) => state.posts
  );


  /* Gestion Modals */
  const [isAddPostModalOpen, setIsAddPostModalOpen] = useState(false);
  const [isEditPostModalOpen, setIsEditPostModalOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState<Post | null>(null);

  const fetchPostsData = useCallback(() => {
    dispatch(fetchPosts({ page: currentPage, limit: perPage }));
  }, [dispatch, currentPage, perPage]);

  useEffect(() => {
    fetchPostsData();
  }, [fetchPostsData]);

  const handleEditPost = (post: Post) => {
    setSelectedPost(post);
    setIsEditPostModalOpen(true);
  };

  const handlePageChange = (newPage: number) => {
    dispatch(setCurrentPage(newPage));
  };

  const stats = [
    {
      title: "Ventes Cette Semaine",
      value: `${salesStats?.total_sales_week?.toFixed(2) || '0.00'} €`,
      icon: <Wallet className="w-6 h-6 text-primary" />,
      trend: "up"
    },
    {
      title: "Total des Ventes",
      value: `${salesStats?.total_sales_all_time?.toFixed(2) || '0.00'} €`,
      icon: <TrendingUp className="w-6 h-6 text-primary" />,
      trend: "up"
    },
    {
      title: "Ventes par Post",
      value: salesStats?.average_sales_per_post?.toFixed(0) || '0',
      icon: <Users className="w-6 h-6 text-primary" />,
      trend: "up"
    },
    {
      title: "Taux d'Engagement",
      value: `${salesStats?.engagement_rate || '0'}%`,
      description: `${salesStats?.total_purchases || '0'} achats sur ${salesStats?.total_views || '0'} vues`,
      icon: <Activity className="w-6 h-6 text-primary" />,
      trend: "up"
    }
  ];

  // Formater les données pour le graphique
  const chartData = useSelector((state: RootState) =>
    state.posts.meta?.analytics?.map(post => ({
      name: post.title.substring(0, 15) + (post.title.length > 15 ? '...' : ''),
      ventes: post.total_sales,
      montant: post.total_amount,
      date: new Date(post.date).toLocaleDateString('fr-FR')
    })) || []
  );

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="container mx-auto p-6 space-y-8"
      >
        {/* En-tête */}
        <div className="flex justify-between items-center">
          <motion.h1
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            className="text-4xl font-bold bg-gradient-to-r from-primary to-primary/60 bg-clip-text text-transparent"
          >
            Tableau de bord
          </motion.h1>
        </div>

        {/* Grille de statistiques */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {stats.map((stat, index) => (
            <motion.div
              key={stat.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <Card className="hover:shadow-lg transition-all duration-200">
                <CardHeader className="pb-2">
                  <div className="flex items-center justify-between">
                    {stat.icon}
                    <span className={`text-sm font-medium ${
                      stat.trend === 'up' ? 'text-green-500' : 'text-red-500'
                    }`}>
                    </span>
                  </div>
                  <CardTitle className="text-2xl font-bold mt-2">{stat.value}</CardTitle>
                  <CardDescription>
                    {stat.title}
                    {stat.description && (
                      <div className="text-xs mt-1 text-muted-foreground">
                        {stat.description}
                      </div>
                    )}
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <Progress
                    value={75}
                    className="h-2"
                    aria-label={`Progress for ${stat.title}`}
                  />
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </div>

        {/* Onglets de contenu */}
        <Tabs defaultValue="publications" className="space-y-6">
          <TabsList className="grid w-full grid-cols-2 lg:w-[400px]">
            <TabsTrigger value="publications">Publications</TabsTrigger>
            <TabsTrigger value="analytics">Analytiques</TabsTrigger>
          </TabsList>

          <TabsContent value="publications" className="space-y-6">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              <Card>
                <CardHeader>
                  <div className="flex items-center justify-between">
                    <CardTitle>Publications récentes</CardTitle>
                    <Button onClick={() => setIsAddPostModalOpen(true)}>
                      Nouvelle publication
                    </Button>
                  </div>
                  <CardDescription>
                    Gérez vos {posts.length} publications
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  {posts.length > 0 && (
                    <PostsTable
                      postData={posts}
                      onEditPost={handleEditPost}
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  )}
                </CardContent>
              </Card>
            </motion.div>
          </TabsContent>

          <TabsContent value="analytics">
            <SalesChart data={chartData} />
          </TabsContent>
        </Tabs>

        {/* Modals */}
        <AddPostModal
          isOpen={isAddPostModalOpen}
          onClose={() => setIsAddPostModalOpen(false)}
        />
        <EditPostModal
          isOpen={isEditPostModalOpen}
          onClose={() => setIsEditPostModalOpen(false)}
          post={selectedPost}
        />
      </motion.div>
    </AnimatePresence>
  );
};

export default DashboardPage;
