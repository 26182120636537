import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { checkSessionStatus } from "@/app/reducers/PaymentReducers";
import { AppDispatch } from "@/app/store";

function Success() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkSession = async () => {
      const sessionId = new URLSearchParams(window.location.search).get(
        "session_id"
      );

      if (sessionId) {
        try {
          const response = await dispatch(
            checkSessionStatus(sessionId)
          ).unwrap();

          if (response.metadata?.uuid) {
            const localData = localStorage.getItem("picke_me_stripedata");
            let data = localData ? JSON.parse(localData) : [];

            const sessionExists = data.some(
              (item: any) => item.sessionId === response.sessionId
            );

            if (!sessionExists) {
              data.push({
                postId: response.metadata.postId,
                price: response.metadata.price,
                uuid: response.metadata.uuid,
                sessionId: response.sessionId,
              });
              localStorage.setItem("picke_me_stripedata", JSON.stringify(data));
            }

            setTimeout(() => {
              navigate(`/share/${response.metadata.uuid}`);
            }, 3000);
          } else {
            console.error("UUID non trouvé dans les métadonnées");
            setIsLoading(false);
          }
        } catch (error) {
          console.error("Erreur lors de la vérification du statut:", error);
          setIsLoading(false);
        }
      }
    };

    checkSession();
  }, [dispatch, navigate]);

  return (
    <div className="bg-gray-100 h-screen flex justify-center items-center">
      <div className="bg-white p-6 md:mx-auto">
        <svg
          viewBox="0 0 24 24"
          className="text-green-600 w-16 h-16 mx-auto my-6"
        >
          <path
            fill="currentColor"
            d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
          ></path>
        </svg>
        <div className="text-center">
          <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
            Paiement effectué !
          </h3>
          <p className="text-gray-600 my-2">
            Merci d'avoir effectué votre paiement en ligne sécurisé.
          </p>
          <p className="text-gray-600 mt-4 mb-6">
            Vous allez être redirigé dans un instant...
          </p>
          {isLoading && (
            <div className="flex justify-center">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Success;
